import React, { useEffect, useContext } from "react";
import SEO from "../components/SEO";
import SchoolsHeroSection from "@src/components/partials/schools/SchoolsHeroSection";
import PerksSection from "@src/components/partials/schools/PerksSection";
import TraitsSection from "@src/components/TraitsSection";
import AlternatedFeaturesSection from "@src/components/AlternatedFeaturesSection";
import FeedbacksSection from "@src/components/FeedbacksSection";
import ResourcesSection from "@src/components/partials/schools/ResourcesSection";
import FAQSection from "@src/components/partials/schools/FAQSection";
import CTASection from "@src/components/CTASection";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const Schools = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        common_data_schools {
          traits
          features
          feedbacks
          faq_items
          classdojo_help_link_url
          cta_background_image_url
        }
        page_schools {
          hero_button_url
          hero_video_image {
            id
            filename_disk
          }
          hero_video_url
          balloons_badge_image {
            id
            filename_download
            filename_disk
          }
          star_badge_icon {
            id
            filename_download
            filename_disk
          }
          star_badge_alt
          resources_image {
            id
            filename_download
            filename_disk
          }
          resources_button_url
          resources_webinar_button_url
          resources_wave_top_image {
            id
            filename_download
            filename_disk
          }
          resources_wave_bottom_image {
            id
            filename_download
            filename_disk
          }
          perks
          perks_check_icon {
            id
            filename_download
            filename_disk
          }
          perks_check_alt
          cta_button_url
        }
        page_schools_2023 {
          redesign_hero_button_url
          redesign_hero_tepsa_button_url
          redesign_hero_button_contact_url
          redesign_hero_video_image {
            id
            filename_disk
          }
          redesign_hero_video_url
          redesign_traits
          redesign_traits_callout_image {
            id
            filename_download
            filename_disk
          }
          redesign_faq_items
          redesign_schoolwide_items
          redesign_schoolwide_button_url
          redesign_schoolwide_image {
            id
            filename_download
            filename_disk
          }
          redesign_cta_image {
            id
            filename_download
            filename_disk
          }
          redesign_cta_button_url
          redesign_cta_button_contact_url
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      common_data_schools: {
        features,
        feedbacks,
        traits,
        faq_items,
        classdojo_help_link_url,
        cta_background_image_url,
      },
      page_schools: {
        hero_video_image,
        hero_video_url,
        hero_button_url,
        star_badge_icon,
        balloons_badge_image,
        resources_image,
        resources_webinar_button_url,
        resources_button_url,
        perks,
        perks_check_icon,
        cta_button_url,
      },
      page_schools_2023: {
        redesign_hero_button_url,
        redesign_hero_tepsa_button_url,
        redesign_hero_button_contact_url,
        redesign_hero_video_image,
        redesign_hero_video_url,
        redesign_traits,
        redesign_traits_callout_image,
        redesign_faq_items,
        redesign_schoolwide_items,
        redesign_schoolwide_button_url,
        redesign_schoolwide_image,
        redesign_cta_image,
        redesign_cta_button_url,
        redesign_cta_button_contact_url,
      },
    },
  } = data;

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    logEvent({
      eventName: "web.external.schools-new.load_page",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const SchoolsHeroProps = {
    hero_title: "directus.page_schools.hero_title",
    hero_text: "directus.page_schools.hero_text",
    hero_button_text: "directus.page_schools.hero_button_text",
    hero_button_advice: "directus.page_schools.hero_button_advice",
    hero_video_image,
    hero_video_alt: "directus.page_schools.hero_video_alt",
    hero_video_url,
    hero_button_url,
    hero_tepsa_button_text: "directus.page_schools_2023.redesign_hero_tepsa_button_text",
    hero_tepsa_button_url: redesign_hero_tepsa_button_url,
    star_badge_icon,
    star_badge_alt: "directus.page_schools.star_badge_alt",
    balloons_badge_image,
    balloons_badge_alt: "directus.page_schools.balloons_badge_alt",
    sign_up_tooltip_text: "directus.page_schools.sign_up_tooltip_text",
    learn_more_button_text: "directus.page_schools.learn_more_button_text",
    button_log_event_name: "teacher.classdojo_school.click_get_started",
    params,
  };

  const ResourcesSectionProps = {
    resources_title: "directus.page_schools.resources_title",
    resources_button_text: "directus.page_schools.resources_button_text",
    resources_message_button_text: "directus.page_schools.resources_message_button_text",
    resources_button_url,
    resources_help_text: "directus.page_schools.resources_help_text",
    resources_webinar_button_text: "directus.page_schools.resources_webinar_button_text",
    resources_webinar_button_href: resources_webinar_button_url,
    resources_image,
    resources_image_alt: "directus.page_schools.resources_image_alt",
    params,
    location,
  };

  const AlternatedFeaturesSectionProps = {
    invert_desktop_order: true,
    title: "directus.common_data_schools.features_title",
    text: "directus.common_data_schools.features_text",
    content: features.map((feature, index) => ({
      ...feature,
      title: `directus.common_data_schools.features.title_${index + 1}`,
      text: `directus.common_data_schools.features.text_${index + 1}`,
    })),
    textOnTop: false,
  };

  const TraitsSectionProps = {
    traits_title: "directus.common_data_schools.traits_title",
    content: traits,
    translationPath: "directus.common_data_schools.traits.",
    colored_bg: false,
    border_on_top: false,
    title_size: false,
  };

  const PerksSectionProps = {
    star_badge_icon,
    star_badge_alt: "directus.page_schools.star_badge_alt",
    perks_title: "directus.page_schools.perks_title",
    perks_text: "directus.page_schools.perks_text",
    perks,
    perks_check_icon,
    perks_check_alt: "directus.page_schools.perks_check_alt",
  };

  const FeedbacksSectionProps = {
    feedbacks_title: "directus.common_data_schools.feedbacks_title",
    feedbacks_numbers: "directus.common_data_schools.feedbacks_numbers",
    feedbacks_text: "directus.common_data_schools.feedbacks_text",
    feedbacks_text_source: "directus.common_data_schools.feedbacks_text_source",
    content: feedbacks,
    translationPath: "directus.common_data_schools.feedbacks.",
    colored_mode: true,
    back_slide_button_alt: "directus.common_data_schools.back_slide_button_alt",
    foward_slide_button_alt: "directus.common_data_schools.foward_slide_button_alt",
  };

  const FAQSectionProps = {
    faq_title: "directus.common_data_schools.faq_title",
    faq_items,
    faq_item_caret_alt: "directus.common_data_schools.faq_item_caret_alt",
    translationPath: "directus.common_data_schools.",
    faq_question: "directus.common_data_schools.faq_question",
    classdojo_help_link_text: "directus.common_data_schools.classdojo_help_link_text",
    classdojo_help_link_url,
  };

  const CTASectionProps = {
    cta_title: "directus.common_data_schools.cta_title",
    cta_button_text: "directus.common_data_schools.cta_button_text",
    cta_button_url,
    cta_background_image_url: cta_background_image_url,
    cta_advice: "directus.common_data_schools.cta_advice",
    button_log_event_name: "teacher.classdojo_school.click_get_started",
    params,
  };

  const RedesignSchoolsHeroProps = {
    hero_wordmark: "directus.page_schools_2023.redesign_hero_wordmark",
    hero_title: "directus.page_schools_2023.redesign_hero_title",
    hero_text: "directus.page_schools_2023.redesign_hero_text",
    hero_button_text: "directus.page_schools_2023.redesign_hero_button_text",
    hero_button_contact_text: "directus.page_schools_2023.redesign_hero_button_contact_text",
    hero_video_image: redesign_hero_video_image,
    hero_video_alt: "directus.page_schools_2023.redesign_hero_video_alt",
    hero_video_url: redesign_hero_video_url,
    hero_button_url: redesign_hero_button_url,
    hero_button_contact_url: redesign_hero_button_contact_url,
    star_badge_icon,
    star_badge_alt: "directus.page_schools.star_badge_alt",
    button_log_event_name: "teacher.classdojo_school.click_get_started",
    params,
    have_questions: "directus.page_schools_2023.have_questions",
  };

  const RedesignTraitsSectionProps = {
    traits_title: "directus.page_schools_2023.redesign_traits_title",
    traits_subtitle: "directus.page_schools_2023.redsign_traits_subtitle",
    traits_overline: "directus.page_schools_2023.redesign_traits_icons_overline",
    content: redesign_traits,
    translationPath: "directus.page_schools_2023.redesign_traits.",
    colored_bg: false,
    title_size: false,
    callout_image: redesign_traits_callout_image,
    callout_alt: "directus.page_schools_2023.redesign_traits_callout_alt",
    callout_text: "directus.page_schools_2023.redesign_traits_callout_text",
    callout_footnote: true,
  };

  const RedesignFeedbacksSectionProps = {
    feedbacks_title: "directus.common_data_schools.feedbacks_title",
    feedbacks_numbers: "directus.page_schools_2023.redesign_feedback_number",
    feedbacks_text: "directus.page_schools_2023.redesign_feedback_text",
    feedbacks_text_source: "",
    content: feedbacks,
    translationPath: "directus.common_data_schools.feedbacks.",
    colored_mode: true,
    back_slide_button_alt: "directus.common_data_schools.back_slide_button_alt",
    foward_slide_button_alt: "directus.common_data_schools.foward_slide_button_alt",
    horizontal_mode: true,
  };

  const RedesignFAQSectionProps = {
    faq_title: "directus.page_schools_2023.redesign_faq_title",
    faq_items: redesign_faq_items,
    faq_item_caret_alt: "directus.common_data_schools.faq_item_caret_alt",
    translationPath: "directus.page_schools_2023.",
    help_text: "directus.page_schools_2023.redesign_faq_help_text",
  };

  const RedesignCTASectionProps = {
    cta_title: "directus.page_schools_2023.redesign_cta_text",
    cta_button_text: "directus.page_schools_2023.redesign_cta_button_text",
    cta_button_url: redesign_cta_button_url,
    cta_button_contact_text: "directus.page_schools_2023.redesign_cta_button_contact_text",
    cta_button_contact_url: redesign_cta_button_contact_url,
    cta_background_image_url: redesign_cta_image.file.publicURL,
    cta_advice: "directus.common_data_schools.cta_advice",
    button_log_event_name: "teacher.classdojo_school.click_get_started",
    params,
  };

  const RedesignSchoolwideProps = {
    schoolwide_title: "directus.page_schools_2023.redesign_schoolwide_title",
    schoolwide_items: redesign_schoolwide_items,
    translationPath: "directus.page_schools_2023.redesign_schoolwide_items.",
    schoolwide_button_text: "directus.page_schools_2023.redesign_schoolwide_button_text",
    schoolwide_button_link: redesign_schoolwide_button_url,
    schoolwide_image: redesign_schoolwide_image,
    schoolwide_image_alt: "directus.page_schools_2023.redesign_schoolwide_image_alt",
  };

  return (
    <>
      <SEO
        title="directus.page_schools_2023.SEO_title"
        description="directus.page_schools_2023.SEO_description"
        image="https://static.classdojo.com/img/schoolleader/schoolleader-share.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Become a ClassDojo School",
          description:
            "ClassDojo gives your entire school one place to connect, communicate and share their learning for free",
          image: "https://static.classdojo.com/img/schoolleader/schoolleader-share.png",
        }}
      />
      <SchoolsHeroSection {...SchoolsHeroProps} />
      <TraitsSection {...TraitsSectionProps} />
      <PerksSection {...PerksSectionProps} />
      <AlternatedFeaturesSection {...AlternatedFeaturesSectionProps} />
      <FeedbacksSection {...FeedbacksSectionProps} />
      <ResourcesSection {...ResourcesSectionProps} />
      <FAQSection {...FAQSectionProps} />
      <CTASection {...CTASectionProps} />
    </>
  );
};

export default Schools;
